import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "sushkin_contract" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>4 Мартa 2020 Catenaccio Sports Agency добыла важную победу в Палате по Решению Споров ФИФА для своего клиента, Вячеслава Сушкина. Спор возник из-за того что игрок в одностороннем порядке расторг трудовое соглашение.</p>
          <p>Иск о нарушении соглашения был предъявлен на основании принципа «справедливый повод» в интересах Вячеслава, чьи трудовые права на медицинское обслуживание и финансовые выплаты были пренебрежены футбольным клубом из Узбекистана. Catenaccio оперативно выявило ключевую погрешность  в трудовом соглашении и сформировала объёмную доказательную базу, аргументируя то что клуб являлся ответственным за раннее расторжение соглашения игроком.</p>
          <p>Палата по Разрешению Споров приняла сторону аргументов выдвигаемых Catenaccio, отвергла все утверждения клуба и, таким образом, подтвердила законность раннего и преждевременного расторжения трудового соглашения игроком. Catenaccio убедило ФИФА присудить Вячеславу значительную финансовую компенсацию.</p>
        </div>
      </div>
    )
  }
}